<template>
  <div class="article-container">
    <div class="main-wrap">
      <!-- 加载中 -->
      <div v-if="loading"
           class="loading-wrap">
        <van-loading color="#3296fa"
                     vertical>مازمۇن ىزدەلۋدە</van-loading>
      </div>
      <!-- /加载中 -->

      <!-- 加载完成-文章详情 -->
      <div class="article-detail"
           v-if="!loading">
        <!-- 文章标题 -->
        <h1 class="article-title">{{ detail.title }}</h1>
        <!-- 视频播放器 -->
        <div class="videoControl"
             v-if="detail.type === 'video'">
          <video-player v-if="openId"
                        class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions"
                        @timeupdate="onPlayerTimeupdate($event)"></video-player>
        </div>

        <div class="tabs"
         v-if="lessons.length > 1">
      <van-cell class="article-item"
                @click="onSelect(article)"
                v-for="(article, index) in lessons"
                :key="index">
        <div slot="title"
             class="title van-multi-ellipsis--l2"
             :class="
            detail.is_free == 1 && article.is_free == 2 ? 'text-grey' : ''
          ">
          <p style="display: inline-block; margin-left: auto"
             :class="article.id == currentId ? 'text-red' : ''">
            {{ article.title }}
          </p>
          <i v-if="
              (detail.is_free == 1 && article.is_free == 1) ||
              (detail.is_free == 2 && article.is_free == 2) ||
              (detail.is_free == 2 && article.is_free == 1)
            "
             class="icon-lock-open1"
             style="color: #999"></i>
          <i v-if="detail.is_free == 1 && article.is_free == 2"
             class="icon-lock"></i>
        </div>
      </van-cell>

      <!-- <div class="indexItem">
        <div @click="onSelect(article)"
             v-for="(article, index) in lessons"
             :key="index"
             class="item">
          <div class="tag_item"
               :class="article.id == currentId ? 'light' : ''">
            {{ article.title }}
            <span class="vips bg-gradual-orange"
                  v-if="article.is_free == '2' && detail.is_free == '1'">اقىلى</span>
          </div>
        </div>
      </div> -->

      <van-empty description="قازىرشە ساباق قوسىلمادى"
                 v-show="!lessons.length" />
    </div>

        <!-- 文章展示区域 -->
        <div v-if="detail.description"
             ref="article-content"
             class="article-content markdown-body"
             v-html="detail.description"></div>
      </div>

      <!-- /加载完成-文章详情 -->
    </div>

    <!-- <div class="ershida">
      <p>«قوسىلايىق» باعدارلاماسىنىڭ ءار كۇنگى بارلىق بەرىلىمدەرىن كورمەكشى بولساڭىز تومەندەگى ەكىلىكتى جايمالاپ كورىڭىز</p>
      <img src="https://mmbiz.qpic.cn/sz_mmbiz_jpg/FoJMCF6BgbpcFtzPXUa0Weic3RMpMqXeDDgfdLan5W6y50r2KSiapnB8C9TkYIGskhLk4aJ7EZmaiarTSw8Uu7r3Q/640?wx_fmt=jpeg&amp;from=appmsg" />
    </div> -->

    <!-- 赞赏功能模块 -->
    <!-- <div class="appreciates">
      <van-button type="danger"
                  round
                  @click="onTapShowZanshang">ەرىكتى قولداۋ ءبىلدىرۋ</van-button>
    </div> -->

    <!-- <div class="appreciatesList"
         v-if="appreciates.length">
      <van-divider :style="{
          color: '#666',
          borderColor: '#666',
          padding: '16px',
          margin: 0,
        }">{{ appreciates.length }} ادام قولدادى</van-divider>
      <div class="avatars">
        <van-image v-for="item in appreciates"
                   :key="item.uid"
                   radius="4"
                   :src="item.user.avatar_url" />
      </div>
    </div> -->

    <van-popup class="payContainer"
               v-model="showPay"
               closeable>
      <div>
        <h4>تولىعىن كورۋ ءۇشىن تەكشەنى قولداپ ساتىپ الىڭىز</h4>
        <p class="p1">
          <!-- باعاسى <span>{{ detail.price }}</span> يۋان -->
          باعاسى تەك عانا <span>{{ detail.price }}</span> يۋان
        </p>
        <p class="p4">
          <van-button block
                      type="danger"
                      round
                      size="small"
                      @click="_pay">ساتىپ الۋ</van-button>
        </p>
      </div>
    </van-popup>

    <!-- <van-popup class="payContainer zanshang"
               v-model="showZanshang"
               closeable>
      <div>
        <h4>ەڭبەگىمىزدى قولداڭىز</h4>
        <p class="p4"
           v-if="showPriceList">
          <van-button v-for="item in zanShangList"
                      :key="item"
                      block
                      type="danger"
                      size="small"
                      plain
                      @click="_payZanshang(item)">{{ item }} يۋان</van-button>
        </p>
        <van-field v-model="zanshangPrice"
                   input-align="right"
                   v-if="!showPriceList"
                   placeholder="باعانى جازىڭىز"
                   type="number" />
        <van-button class="submitBtn"
                    v-if="!showPriceList"
                    type="danger"
                    block
                    round
                    size="small"
                    @click="submitZanshang">تۇراقتاندىرۋ</van-button>
        <p class="userInput"
           @click="inputPrice">باسقا باعا</p>
      </div>
    </van-popup> -->

    <div style="height: 70px; background-color: #333"></div>
    <div class="footer">ARNASOFT 技术提供</div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { getArticleById, getArticles, courseDetail } from '@/api/article'
import {
  createAppreciates,
  orderPayStatus,
  getAppreciates
} from '@/api/appreciates'
import { getUrl } from '@/api/getTxUrl'
import { pay, payResult } from '@/api/pay'
import { wechatRedirect } from '@/api/user'
import { getItem } from '@/utils/storage'
import { getRecomList } from '@/api/home'
import { initShareInfo } from '@/utils/util'
import { Toast } from 'vant'

export default {
  name: 'ArticleIndex',
  // 给所有的后代组件提供数据
  // 注意：不要滥用，如果一个父组件里面的所有子组件（所有后代组件）都需要的话可以这么传过来
  provide () {
    return {
      parent_id: this.parent_id,
      type: this.type
    }
  },
  data () {
    return {
      zanShangList: [1, 2, 5, 10, 20, 50, 100, 200, 500],
      loading: true,
      detail: {}, // 父类详情
      article: {}, // 文章详情
      errStatus: 0, // 失败的状态码
      userInfo: {}, // 用户信息
      txUrl: '',
      show: false,
      lessons: [],
      currentId: '',
      currentVideo: {},
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        isPIP: true,
        isFullscreen: true,
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: '', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: '' // url地址
          }
        ],
        poster: '', // 你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      },
      courses: [],
      audio: {
        name: '👈🏻  كىنوپكانى باسىپ تىڭداڭىز',
        artist: '',
        url: '',
        cover: ''
      },
      volume: 0.7,
      showPay: false,
      openId: '',
      showZanshang: false,
      zanShangParams: {},
      appreciates: [],
      showPriceList: true,
      zanshangPrice: null
    }
  },
  watch: {
    $route (to, from) {
      if (to.fullPath === to.path) {
        // 重新渲染数据
        this.getCourseDetail()
      }
    }
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  mounted () {
    setTimeout(() => {
      const userInfo = getItem('userInfo')
      const openId = this.$cookies.get('openId')
      this.openId = openId
      if (userInfo) {
        this.userInfo = userInfo
        this.getCourseDetail()
      }
    }, 600)
  },
  methods: {
    async getAllAppreciates () {
      try {
        const { data } = await getAppreciates(this.$route.params.parent_id)
        this.appreciates = data.data
      } catch (error) {
        console.error(error)
      }
    },
    onTapShowZanshang () {
      this.showZanshang = true
    },
    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate ($event) {
      const detail = $event.cache_
      const currentTime = Math.floor(detail.currentTime)
      const priceTime = parseInt(this._handleTime())
      if (
        currentTime >= priceTime &&
        parseInt(this.detail.is_free) !== 2 &&
        parseInt(this.currentVideo.is_free) === 2
      ) {
        this.$refs.videoPlayer.player.pause()
        return (this.showPay = true)
      }
    },
    _handleTime () {
      const time = this.detail.price_time
      if (time) {
        const min = time.split(':')[0]
        const sec = time.split(':')[1]
        const second = Number(Number(min * 60) + Number(sec))
        return second
      }
    },
    async getCourseDetail () {
      try {
        const { data } = await courseDetail({
          vid: this.$route.params.parent_id
        })
        this.detail = data.course
        if (data.course.type === 'audio') this.audio.cover = data.course.image
        if (data.course.type === 'video') {
          this.playerOptions.poster = data.course.image
        }

        this.getLessons()
        wx.ready(() => {
          initShareInfo({
            title: data.course.title, // 分享标题
            desc: 'دوستارعا، دوستىق شەڭبەرگە ورتاقتاسىڭىز',
            imgUrl: data.course.image // 分享图标
          })
        })
      } catch (error) {
        console.error(error)
      }
    },
    // 当触发上拉加载更多的时候调用该函数
    async getLessons () {
      try {
        // 1. 请求获取数据
        const { data } = await getArticles({
          vid: this.$route.params.parent_id,
          m_show: 'N'
        })
        // 2. 把数据添加到 list 数组中
        this.lessons = data.data.reverse()
        this.currentId = this.lessons[0].id
        this.currentVideo = this.lessons[0]
        this.loadArticle()
      } catch (err) {
        console.error(err)
      }
    },
    async loadArticle () {
      this.loading = true
      try {
        const { data } = await getArticleById(this.currentId)
        this._getTxUrl({
          vid: data.wxv_id,
          url: data.gz_url,
          local_url: data.local_url
        })
        if (['audio', 'article'].includes(this.$route.params.type)) {
          this.audio.url = data.gz_url
          this.audio.name = data.title
        }

        this.article = data
        this.article.created_at = data.created_at.split(' ')[0]
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    async _getTxUrl ({ vid, url, local_url: localUrl }) {
      if (this.$route.params.type === 'video') {
        if (localUrl) {
          this.playerOptions.sources[0].src = localUrl
        } else {
          const { data: tencent } = await getUrl({ vid, url })
          this.playerOptions.sources[0].src = tencent
        }
      }
      this.loading = false
    },

    onSelect (item) {
      if (parseInt(this.detail.is_free) === 1 && parseInt(item.is_free) === 2) {
        this.showPay = true
        return
      }

      if (this.$route.params.type === 'video') {
        this._getTxUrl({
          vid: item.wxv_id,
          url: item.gz_url,
          local_url: item.local_url
        })
      }
      if (['audio', 'article'].includes(this.$route.params.type)) {
        this.audio.url = item.gz_url
        this.audio.name = item.title
      }
      this.article = item
      this.article.created_at = item.created_at.split(' ')[0]
      this.currentId = item.id
      this.currentVideo = item
      this.show = false
      document.documentElement.scrollTop = 0
    },

    async _pay () {
      const _this = this
      _this.showPay = false
      const openId = _this.$cookies.get('openId')
      if (!openId) {
        location.href = `${wechatRedirect}?url=${encodeURIComponent(
          location.href
        )}&scope=snsapi_userinfo`
      }

      // 支付请求参数
      const params = {
        goods_name: _this.detail.title,
        goods_id: _this.detail.id,
        price: _this.detail.price,
        openId
      }
      const { code, data } = await pay(params)
      if (code === 200) {
        wx.chooseWXPay({
          timestamp: data.args.timeStamp,
          nonceStr: data.args.nonceStr,
          package: data.args.package,
          signType: data.args.signType,
          paySign: data.args.paySign,
          success (res) {
            if (res.errMsg === 'chooseWXPay:ok') {
              payResult({
                payState: 'success',
                goods_name: _this.detail.title,
                goods_id: _this.detail.id,
                price: _this.detail.price,
                order_number: data.orderInfo.order_number
              })

              setTimeout(() => {
                _this.getCourseDetail()
              }, 500)
            } else if (res.errMsg === 'chooseWXPay:fail') {
              _this._userCancel()
            }
          },
          cancel: function () {
            _this._userCancel()
          },
          fail: function (res) {
            alert(res.message || 'اقشا تولەۋ ءساتسىز بولدى')
          }
        })
      }
    },
    // 用户取消付款提示
    async _userCancel () {
      try {
        await this.$dialog.confirm({
          title: 'ەسكەرتۋ',
          message:
            'ءسىز اقشا تولەۋدى كۇشىنەن قالدىردىڭىز، قايتالاي اقشا تولەۋدى باسىڭىز!',
          confirmButtonText: 'اقشا تولەۋ',
          cancelButtonText: 'قالدىرۋ'
        })
        this._pay()
      } catch (error) {
        console.log(error)
      }
    },
    async getRecomList () {
      try {
        const res = await getRecomList({
          recommend: 1,
          pagesize: 10,
          random: 'Yes',
          cateId: this.detail.cate_id
        })
        if (res.code === 200 && res.errorCode === 0) {
          this.courses = res.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async _payZanshang (item) {
      const _this = this
      const openId = _this.$cookies.get('openId')
      if (!openId) {
        location.href = `${wechatRedirect}?url=${encodeURIComponent(
          location.href
        )}&scope=snsapi_userinfo`
      }

      _this.zanShangParams = {
        memberName: `${item} يۋان قولداۋ`,
        openId: openId,
        price: item * 100,
        memberId: _this.$route.params.parent_id
      }

      _this.showZanshang = false

      const { code, data } = await createAppreciates(_this.zanShangParams)
      if (code === 200) {
        wx.chooseWXPay({
          timestamp: data.timeStamp,
          nonceStr: data.nonceStr,
          package: data.package,
          signType: data.signType,
          paySign: data.paySign,
          success: async (res) => {
            if (res.errMsg === 'chooseWXPay:ok') {
              orderPayStatus('success', {
                ..._this.zanShangParams,
                orderNumber: data.order_number
              })

              setTimeout(() => {
                location.reload()
              }, 500)
            } else if (res.errMsg === 'chooseWXPay:fail') {
              _this._userCancel()
            }
          },
          cancel: function () {
            // _this._userCancel()
          },
          fail: async (res) => {
            alert(res.message || 'اقشا تولەۋ ءساتسىز بولدى')
          }
        })
      }
    },
    inputPrice () {
      this.showPriceList = !this.showPriceList
    },
    submitZanshang () {
      if (!this.zanshangPrice) {
        Toast('قولدايتىن باعانى جازىڭىز')
        return
      }
      this._payZanshang(this.zanshangPrice)
    }
  },
  destroyed () {
    // show share menu
    wx.showOptionMenu()
    if (['audio', 'article'].includes(this.$route.params.type)) {
      this.audio.url = ''
      this.audio.name = ''
    }
  }
}
</script>

<style scoped lang="less">
@import './css/github-markdown.css';
.article-container {
  direction: rtl;
  height: 100vh;
  background-color: #333333;
  .main-wrap {
    overflow-y: scroll;

    .qrCode {
      text-align: center;
      padding-bottom: 40px;
      img {
        width: 400px;
        height: 400px;
        border: 1px solid #e3e7ea;
        border-radius: 8px;
      }

      p {
        font-size: 36px;
        color: #888;
        margin-bottom: 30px;
      }
    }
  }

  .tabs {
    padding: 20px;
    background-color: #333333;
    margin-bottom: 10px;

    /deep/ .van-cell {
      background-color: #333333;
      color: #ccc;
      &::after {
        border-color: rgb(87, 85, 85) !important;
      }
    }

    .indexItem {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background-color: #333333;
      .item {
        display: flex;
        justify-content: center;
        width: 20%;
        margin: 20px 0;
        background-color: transparent;
      }

      .tag_item {
        position: relative;
        height: 100px;
        line-height: 100px;
        font-size: 28px;
        width: 118px;
        border-radius: 5px;
        color: #ccc;
        background-color: #5a5858;
        text-align: center;
      }

      .bg-grey {
        background-color: #8799a3;
        color: #fff;
      }
      .bg-gray {
        background-color: #f0f0f0;
        color: #333;
      }

      .light {
        background-color: #e7ebed;
        color: #333;
      }

      .vips {
        position: absolute !important;
        top: 0;
        right: 0;
        pointer-events: none;
        font-size: 18px;
        font-family: auto;
        padding: 0px 4px;
        border-radius: 6px;
        color: #fff;
        margin: 0;
        line-height: 26px;
        height: 26px;
        line-height: 26px;
        z-index: 9;
        font-family: 'Kerwen Tor';
      }

      .bg-gradual-orange {
        background-image: linear-gradient(45deg, #ff9700, #ed1c24);
        color: #fff;
      }
    }

    /deep/ .van-cell {
      background-color: #333333;
      color: #ccc;
    }
  }

  .ershida {
    text-align: center;
    background-color: #333333;
    p {
      font-size: 28px;
      color: #fff;
      margin-bottom: 30px;
    }
    img {
      width: 300px;
      pointer-events: auto !important; /* 禁止长按图片保存 */
    }
    padding: 30px;
    padding-bottom: 0;
  }

  .hd-title {
    background-color: #333333;
    border-bottom-color: #ccc;
    span.name {
      color: #fff;
    }
  }

  /deep/ .topic {
    background-color: #333;
    li {
      .cate_name {
        color: #ccc !important;
      }
    }
  }

  .article-detail {
    position: relative;
    .videoControl {
      margin: 30px;
      .login-tips {
        margin-top: 40px;
        color: #ed1c24;
        font-size: 30px;
        text-align: justify;
        // p {
        //   color: #ed1c24;
        //   font-size: 34px;
        //   text-align: justify;
        // }
        // img {
        //   width: 100%;
        //   height: 500px;
        // }
      }
    }
    .audioControl {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      direction: ltr;
      /deep/ .aplayer {
        width: 92%;
        .aplayer-title {
          position: absolute;
          right: 20px;
          color: #666;
          direction: rtl;
          width: 500px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .aplayer-list {
          direction: rtl;

          .aplayer-list-index {
            margin-right: 0;
            margin-left: 12px;
          }
        }
      }
    }
    .article-title {
      font-size: 40px;
      padding: 50px 32px 30px;
      margin: 0;
      color: #ccc;
      text-align: center;
      margin-bottom: 10px;
    }

    .category {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      color: #999;
      width: 91%;
      margin: 0 auto 30px;

      .icon-container {
        display: inline-flex;
        align-items: center;
        .icon {
          width: 40px;
          height: 40px;
          margin-top: -10px;
        }
        > span {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }

      > span.time {
        display: inline-flex;
        align-items: center;
        > span {
          direction: ltr;
          font-family: Arial, Helvetica, sans-serif;
        }
      }

      .teacher {
        font-size: 22px;
      }

      .van-button {
        height: 34px;
        padding-top: 5px;
        margin-top: -10px;
        font-size: 22px;
      }
    }

    .created_at {
      font-size: 24px;
      color: #999;
      direction: ltr;
      font-family: Arial, Helvetica, sans-serif;
      padding-left: 10px;
      margin-bottom: 20px;
    }

    .article-content {
      padding: 0 32px 20px;
      text-align: justify;
      background-color: #333333;
      color: #fff;
      p {
        background-color: #333333 !important;
        color: #fff;
      }
    }

    .article_pic {
      padding: 20px;
    }

    .content-container {
      position: relative;
      min-height: 200px;
      overflow: hidden;
      text-align: center;

      .price_tips {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 300px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
        display: flex;
        justify-content: center;
        .van-button {
          margin-top: 200px;
        }
      }
    }
  }

  .payContainer {
    width: 600px;
    border-radius: 20px;
    text-align: center;
    padding: 40px 30px 30px;
    box-sizing: border-box;
    direction: rtl;
    h4 {
      font-size: 32px;
      margin-bottom: 20px;
      padding: 0 20px;
    }

    p.p1 {
      font-size: 32px;
      margin-bottom: 20px;
      span {
        color: #ee0a24;
      }
    }

    p.p2 {
      font-size: 26px;
      margin-bottom: 20px;
      color: #999;
    }

    p.p3 {
      font-size: 26px;
      color: #999;
      margin-bottom: 30px;
    }

    p.p4 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      direction: rtl;
      margin-top: 30px;
      .van-button {
        width: 400px;
        font-size: 30px !important;
      }
    }
  }

  .zanshang {
    p.p4 {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .van-button {
        width: 160px !important;
        font-size: 30px !important;
        margin: 10px;
      }
    }

    p.userInput {
      color: rgb(53, 81, 136);
      font-size: 30px;
      margin-top: 30px;
    }

    .submitBtn {
      margin-top: 30px;
      width: 60%;
      height: 80px;
      margin: 30px auto;
    }
  }

  .appreciates {
    display: flex;
    justify-content: center;
    background-color: #333333;
    color: #fff;
    padding-top: 40px;
    .van-button {
      width: 300px;
      height: 80px;
    }
  }

  .appreciatesList {
    background-color: #333;
    .avatars {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .van-image {
        width: 60px;
        height: 60px;
        margin: 6px;
      }
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 30px;
    background-color: #444;
    color: #ccc;
    text-align: center;
    padding: 30px 0;
    z-index: 999;
    direction: rtl;
  }
}
</style>
